<template>
  <div>
    <v-fab-transition>
      <v-btn
        color="success"
        small text icon
        @click.stop="btnClick"
        :loading="loading"
        v-if="!isVerified && !isVerifiedPlayer"
      >
        <v-icon>fas fa-key</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Mobile Confirmation</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="color1 color1Text--text"
            @click.stop="dialog=false; reset()"
            small fab
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
          <v-window v-model="window">
            <v-window-item :key="0">
              <v-card-text class="text-center">
                <div>In order to complete the registration, we need to confirm your mobile phone number</div>
                <v-text-field
                    name="playerPhone"
                    :label="label"
                    v-model="model.phone"
                    v-mask="'(###) ###-####'"
                    :rules="$v.model.phone.$dirty ? [
                      () => $v.model.phone.required || 'A mobile phone number is required',
                      () => $v.model.phone.ValidUSPhone || 'A valid mobile phone number is required',
                    ] : []"
                    @blur="$v.model.phone.$touch()"
                    color="color3"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :solo="solo"
                  ></v-text-field>
                  <v-btn
                    color="success white--text"
                    @click.stop="sendCode"
                    :disabled="$v.invalid || !validNumber || isVerified"
                    :loading="loading"
                  >Send Code</v-btn>
                  <v-expand-transition>
                    <div v-if="dialog && isVerified">
                      <v-alert type="success" :value="true" text prominent class="mt-2">
                        You're all set
                      </v-alert>
                      <v-btn color="success" @click.stop="dialog=false; reset()">ok</v-btn>
                    </div>
                  </v-expand-transition>
              </v-card-text>
            </v-window-item>
            <v-window-item :key="1">
              <v-card-text>
              <div class="title">Enter the verficiation code</div>
              <div v-if="!skip">We just sent a text message with a fresh verification code</div>
              <div class="caption">Note: only the last code issued will be valid and codes are only good for 15 minutes</div>
              <v-text-field
                label="Verification Code"
                v-model="vCode"
                color="color3"
                type="number"
                autofocus
              ></v-text-field>
              <v-alert type="error" :value="!!codeError" text prominent>
                {{codeError}}
              </v-alert>
              <div class="text-center pb-3">
                <v-btn
                  color="color3 color3Text--text"
                  :disabled="!validCode"
                  @click.stop="verifyCode"
                  :loading="loading"
                >Go</v-btn>
              </div>
              <div class="caption text-center">Didn't receive a code? <span class="clickable color3--text" @click.stop="reset">Try again.</span></div>
              </v-card-text>
            </v-window-item>
          </v-window>
          <v-card-actions>
            <v-fab-transition>
              <v-btn
                color="color3"
                class="mt-5"
                text x-small
                @click.stop="skipToCode"
                v-if="window === 0"
                :disabled="isVerified"
              >I already have a code</v-btn>
            </v-fab-transition>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ValidUSPhone from '@/helpers/ValidUSPhone'

export default {
  props: ['value', 'label', 'solo', 'disabled', 'placeholder', 'model'],
  directives: { mask },
  mixins: [validationMixin],
  validations () {
    return {
      model: {
        phone: { required, ValidUSPhone }
      }
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      verified: [],
      verifiedPlayers: [],
      window: 0,
      vCode: null,
      codeError: null,
      skip: false
    }
  },
  computed: {
    isVerified () {
      return this.verified.includes(this.cleanNumber)
    },
    isVerifiedPlayer () {
      return this.verifiedPlayers.includes(this.model.playerProfileId)
    },
    validCode () {
      return this.vCode && this.vCode.length === 6
    },
    cleanNumber () {
      const x = this.model.phone
      return x ? x.replace(/\D/g, '') : null
    },
    validNumber () {
      return this.cleanNumber && ValidUSPhone(this.cleanNumber)
    }
  },
  methods: {
    reset () {
      this.window = 0
      this.vCode = null
      this.codeError = null
      this.skip = false
    },
    skipToCode () {
      this.skip = true
      this.window = 1
    },
    btnClick () {
      if (this.isVerified) {
        this.onVerified()
        return
      }
      if (this.isVerifiedPlayer) {
        this.onVerifiedPlayer()
        return
      }
      if (this.validNumber) {
        this.checkNumber()
        return
      }
      this.dialog = true
    },
    checkNumber () {
      if (!this.validNumber || this.isVerified) return
      this.loading = true
      this.$VBL.sms.check(this.cleanNumber)
        .then(r => {
          if (r.data === 'true') {
            this.verified.push(this.cleanNumber)
          } else {
            this.dialog = true
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    checkPlayer () {
      if (this.isVerifiedPlayer) return
      this.loading = true
      this.$VBL.sms.checkPlayer(this.model.playerProfileId)
        .then(r => {
          if (r.data === 'true') {
            this.verifiedPlayers.push(this.model.playerProfileId)
          } else {
            this.dialog = true
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    sendCode () {
      this.loading = true
      this.$VBL.sms.sendCode(this.cleanNumber)
        .then(r => {
          this.window = 1
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    verifyCode () {
      this.loading = true
      this.$VBL.sms.verify(this.cleanNumber, this.vCode)
        .then(r => {
          this.verified.push(this.cleanNumber)
          this.dialog = false
        })
        .catch(e => {
          console.log(e)
          this.codeError = e.response.data.Message
        })
        .finally(() => { this.loading = false })
    },
    onVerified () {
      this.$emit('verified', this.cleanNumber)
    },
    onVerifiedPlayer () {
      this.$emit('verified-player', this.model.playerProfileId)
    }
  },
  watch: {
    dialog: function (v) {
      if (!v) {
        if (!this.isVerifiedPlayer) {
          this.model.onFile = this.model.onFile.filter(f => f !== 'phone')
        }
      }
    },
    isVerified: function (v) {
      if (v) {
        this.onVerified()
      }
    },
    isVerifiedPlayer: function (v) {
      if (v) {
        this.onVerifiedPlayer()
      }
    },
    'model.playerProfileId': function (v) {
      console.log('here')
      if (v) this.checkPlayer()
    }
  },
  mounted () {
    if (this.model.playerProfileId) this.checkPlayer()
  }
}
</script>
